.nav {
  width: 100%;
}

.nav__container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  margin: 0 auto;
}

.nav__home {
  display: none;
  height: 43px;
}

@media only screen and (min-width: 350px) {
  .nav__home {
  display: block;
  }
}

.nav__buttons {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
