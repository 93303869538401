.footer {
  width: 100%;
  background-color: var(--blue);
  color: var(--white);
  display: grid;
  justify-items: center;
}

.footer__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  max-width: 800px;
}

.footer__logo {
  display: flex;
  margin-top: auto;
  grid-column: 1 / 3;
  justify-content: center;
  padding: 40px 0;
  width: 100%;
  align-items: center;
}

.footer__info {
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin: 30px 50px 50px 50px;
}

.footer__info--left,
.footer__info--right {
  grid-column: 1 / 3;
}

@media only screen and (min-width: 425px) {
  .footer__info--left {
    grid-column: 1 / 2;
  }

  .footer__info--right {
    grid-column: 2 / 3;
  }

  .footer__info {
    grid-template-rows: 1fr;
    width: 325px;
    justify-self: center;
  }
}

@media only screen and (min-width: 768px) {
  .footer__container {
    justify-items: center;
    align-items: stretch;
  }

  .footer__logo {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .footer__info {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }
}

.list {
  padding: 0;
}

.list__item {
  list-style: none;
  margin: 12px 0;
  color: rgb(208, 208, 208);
}

.link {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: underline;
  text-decoration-color: transparent;
  color: inherit;
  transition: 0.5s;
}

.link:hover {
  text-decoration: underline;
}

.link__git {
  width: 27px;
  display: block;
  color: #d0d0d0;
}
