.hero {
  width: 100%;
  height: 522px;
  background-image: url('../../images/hero_image__max.jpg');
  background-size: 420%;
  background-position: 80% 35%;
  background-repeat: no-repeat;
}

@media screen and (min-width: 550px) {
  .hero {
    background-size: 170%;
    background-position: 100% 50%;
    height: 60vh;
  }
}

.hero__container {
  max-width: 800px;
  margin: 0 auto;
  padding: 60px 20px 120px 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
}

.hero__logo {
  /* smallest screen 320px */
  width: 316px;
  display: block;
  object-fit: cover;
  object-position: 0% 0%;
}

.hero__message_1,
.hero__message_2 {
  font-size: 1.2rem;
  margin: 0;
}

.hero__span {
  color: var(--blue);
  font-style: italic;
  margin: 0;
}
