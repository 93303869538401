.beer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 30px;
  margin: 0 5px;
  border: var(--border-shadow);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  max-width: 420px;
  position: relative;
}

.beer__title {
  margin: 0;
  max-height: 85px;
  overflow: hidden;
  text-align: center;
}

.badge {
  position: absolute;
  top: 13px;
  left: 13px;
  width: 80px;
  height: 80px;
}

.beer__image {
  object-fit: cover;
  object-position: 50% 50%;
  max-width: 200px;
}

.beer__info {
  padding-bottom: 10px;
}

.info__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.info__container a:last-of-type {
  align-self: center;
}

.type_calories__container {
  justify-self: end;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 10px;
}

.beer__basic__info {
  display: flex;
  flex-direction: column;
}

.social__proof {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.upvote {
  display: flex;
  align-items: center;
  gap: 5px;
}

.upvote__score {
  font-weight: bold;
  font-size: 2rem;
  display: block;
  color: var(--orange);
}

.review__author {
  color: var(--blue);
  font-weight: 700;
  margin: 10px 0;
  text-align: right;
}

.attribute {
  font-weight: bold;
}

@media only screen and (min-width: 425px) {
  .beer {
    flex-direction: row;
    align-items: flex-start;
    margin: 0 5px;
    width: 420px;
  }

  .beer__image {
    width: 100%;
  }
}
