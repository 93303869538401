.review__info {
  display: flex;
  flex-direction: column;
  border: var(--border-shadow);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  margin: 10px;
  padding: 0 20px 20px;
}

.review__header {
  display: flex;
  gap: 10px;
}

.rating {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.rating__star {
  margin-bottom: 3px;
}

.review__title {
  color: var(--orange);
  flex-shrink: 1;
}

.review__author {
  color: var(--blue);
  font-weight: 700;
  margin: 10px 0;
  text-align: right;
}