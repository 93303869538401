.beer__listing__container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 50px 5px;
  max-width: 500px;
  align-self: center;
  padding: 25px;
}

.attribute {
  font-weight: bold;
}

@media only screen and (min-width: 800px) {
  .beer__listing__container {
    max-width: 800px;
    align-items: center;
  }

  .bCard_bInfo {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}
