.log_reg_form {
  
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-bottom: 60px;
  padding: 20px 20px;
  max-width: 500px;
}

label, input {
  display: block;
}

input {
  border: 2px solid #1E6A9C;
  border-radius: 8px;
  width: 285px;
  height: 36px;
}

label {
  font-weight: 700;
  margin-bottom: 2px;
}

.error_message {
  color: #e11111;
  font-weight: 700;
  margin-top: 10px;
  text-align: center;
}

@media only screen and (min-width: 425px) {
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    max-width: 950px;
  }

  label, input {
    display: block;
  }

  label {
    font-weight: 700;
    margin-bottom: 2px;
  }

  input {
    border: 2px solid #1E6A9C;
    border-radius: 8px;
    width: 285px;
    height: 36px;
  }


}
