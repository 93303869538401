/***************************
Root Variables
***************************/
:root {
  --orange: #f26323;
  --blue: #1e6a9c;
  --border-radius: 20px;
  --text-light: #474747;
  --white: #ffffff;
  --border-shadow: 1px solid #e7e6eb;
  --box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
}

/***************************
Resets
***************************/
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Reapply the pointer cursor for anchor tags */
a,
button {
  cursor: revert;
}

/* Remove list styles (bullets/numbers) */
ol,
ul,
menu {
  list-style: none;
}

input,
select,
button {
  font-family: inherit;
  font-size: 1rem;
  border: 1px solid;
}

/* For images to not be able to exceed their container */
img {
  max-width: 100%;
}

body {
  margin: 0;
}

/***************************
Global Styles
***************************/

body {
  font-family: 'Poppins', sans-serif;
  color: var(--text-light);
  background-color: var(--white);
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
