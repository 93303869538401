@media only screen and (min-width: 800px) {
    .social-proof {
      width: 600px;
    }
  }

  .comment_header {
    text-align: center;
  }

  .comment_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-bottom: 5px;
  }