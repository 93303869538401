.button {
  text-decoration: none;
  display: flex;
  justify-content: center;
  min-width: 122px;
  color: var(--white);
  border-radius: 8px;
  padding: 6px 26px;
  background-color: var(--blue);
  border: 3px solid;
  border-color: var(--blue);
  transition: 0.5s;
}

.button:hover {
  filter: brightness(0.8);
}

.button:active {
  scale: 0.95;
}
